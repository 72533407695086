import React from 'react';
import { useEffect, useState } from 'react';
import Scroll from 'react-scroll';

var Element = Scroll.Element;

let socket;

function createSocketConnection() {
  socket = new WebSocket('wss://sandwich.retardmoney.com/')

  //fix socket event listener
}

createSocketConnection()

function Section() {
  const [ txns, setTxns ] = useState([])
  const [ skipped, setSkipped ] = useState([])
  const [ info, setInfo ] = useState('Click on a tx to see more info.')

  socket.addEventListener('message', function (event) {
    const response = event.data
    console.log(JSON.parse(response))
    if (JSON.parse(response)[0].TYPE === 5) {
      setSkipped([0, ...skipped])
      return
    }
    setTxns([JSON.parse(response), ...txns]) 
  })

  function setNewInfo(info) {
    const string = 
    `Token Name: ${info.TOKEN_INFO.baseToken.name} 
    \nToken Address: ${info.TOKEN_INFO.baseToken.address} 
    \nReason: ${JSON.stringify(info.REASON)} 
    \nHash: ${info.HASH} 
    \nMath Info:
        Amount In: ${JSON.stringify(info.MATH_INFO?.AMOUNT_IN)}
        Token Decimal: ${JSON.stringify(info.MATH_INFO?.TOKEN_DEC)}
        Target Reserves: ${JSON.stringify(info.MATH_INFO?.TARGET_RESERVES)}
        Pair Reserves: ${JSON.stringify(info.MATH_INFO?.PAIR_RESERVES)}
        Max BNB Buy: ${JSON.stringify(info.MATH_INFO?.MAX_BUY)}
        Slippage Info: ${JSON.stringify(info.MATH_INFO?.SLIPPAGE_INFO)}
        Min Amount Out: ${JSON.stringify(info.MATH_INFO?.MIN_AMOUNT_OUT)}
        Exploitable Percentage: ${JSON.stringify(info.MATH_INFO?.EXPLOIT_PERC)}
        Price Impact: ${JSON.stringify(info.MATH_INFO?.PRICE_IMPACT)}
        Profit: ${JSON.stringify(info?.PROFIT)}

    \nInput Data: 
        Function Name: ${JSON.stringify(info.INPUT_DATA?.name)} 
        Txn Params: ${JSON.stringify(info.INPUT_DATA?.params)} 

    \nToken Info: 
        Chain ID: ${JSON.stringify(info.TOKEN_INFO?.chainId)}
        DEX: ${JSON.stringify(info.TOKEN_INFO?.dexId)}  
        URL: ${JSON.stringify(info.TOKEN_INFO?.url)}
        Pair Address: ${JSON.stringify(info.TOKEN_INFO?.pairAddress)}  
        Swap Version: ${JSON.stringify(info.TOKEN_INFO.labels)} 
        Target Token: ${JSON.stringify(info.TOKEN_INFO.baseToken)} 
        Pair Token: ${JSON.stringify(info.TOKEN_INFO.quoteToken)} 
        Price USD: ${JSON.stringify(info.TOKEN_INFO.priceUsd)} 
        Pair Created At: ${JSON.stringify(info.TOKEN_INFO.pairCreatedAt)}

    \nSafety Info: 
        ${JSON.stringify(info.SAFETY_INFO)} 

    \nBuy Info: 
        ${JSON.stringify(info.BUY_INFO)}`
        
    setInfo(string)
  }

    return (
      <div class="container">
        <nav className="navbar navbar-default navbar-fixed-top">
          <div className="container-fluid">
            <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
              <ul className="nav navbar-nav">
              </ul>
            </div>
          </div>
        </nav>

        <h1 class="rat-nest">RATS NEST</h1>
        <div class="left-element">
        <Element name="test7" className="element" id="containerElement" style={{
          position: 'relative',
          height: '1000px',
          width: '600px',
          overflow: 'scroll',
          marginBottom: '20px'
        }}>
          

          
          {txns.map((value) => {
                let borderColor;

                if (value[0].TYPE === 0){
                  borderColor = 'black';
                } else if (value[0].TYPE === 1){
                  borderColor = 'green';
                } else if (value[0].TYPE === 2){
                  borderColor = 'purple';
                } else if (value[0].TYPE === 3){
                  borderColor = 'red';
                }
                if (value[0]?.REASON != null) {
                return (
                  <Element name="firstInsideContainer" style={{ marginBottom: '20px' }}>
                    <div onClick={() => setNewInfo(value[0])} style={{ border: `2px solid ${borderColor}`, borderRadius: '7px' }}>
                      <h2>{value[0]?.TOKEN_INFO?.baseToken?.name}</h2>
                      <h3>Status: {value[0]?.REASON}</h3>
                    </div>
                  </Element>
                )};
            })}

                  
         


        </Element>
        </div>
        <Element name="test7" className="element" id="containerElement" style={{
          position: 'relative',
          height: '1000px',
          width: '1000px',
          overflow: 'scroll',
          marginBottom: '20px'
        }}>
        <div class='right-element'>
        <button onClick={() => createSocketConnection()}>REFRESH WEBSOCKET CONNECTION</button>
        <div>
          <h2>Txns parsed: {txns.length - 1}</h2>
        </div>
        <div>
          <h2>Txns skipped: {skipped.length}</h2>
        </div>

        <h1>INFO:</h1>
        <div>
        <h4>{info}</h4>
        </div>
        </div></Element>
      </div>
    );
}



export default Section
